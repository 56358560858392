import Constants from "../utils/constants.utils";

export async function uploadFile(file: any) {
  let result = null;

  const formData = new FormData();
  formData.append("file", file, file.name);
  formData.append("options", `{ "size": 400 }`);

  const url = `${Constants.FILE_API_URL}/api/v1/profiles/${Constants.S3_PROFILE}/files`;

  const req = await fetch(url, {
    headers: {
      'authorization': 'cookie',
    },
    method: 'POST',
    body: formData
  });

  if (req.status === 201) {
    result = await req.json();
  }

  return result;
}
